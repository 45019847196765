import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { Node } from 'relatives-tree/lib/types';
import { Relations } from './Relation.tsx';
import css from './NodeDetails.module.css';
import averageTree from '../Data/average_tree.json';

interface NodeDetailsProps {
  node: Readonly<Node & { firstname: string; name: string }>;
  className?: string;
  onSelect: (nodeId: string | undefined) => void;
  onHover: (nodeId: string) => void;
  onClear: () => void;
}

const findPerson = (id: number) => {
  return averageTree.find(person => person.id === id) || { firstname: 'Unknown', name: 'Unknown' };
};

export const NodeDetails = memo(
  function NodeDetails({ node, className, ...props }: NodeDetailsProps) {
    const closeHandler = useCallback(() => props.onSelect(undefined), [props]);

    const enrichRelations = (relations) => {
      return relations.map(relation => {
        const person = findPerson(Number(relation.id));
        return { ...relation, firstname: person.firstname, name: person.name };
      });
    };
    console.log('Node data:', node);

    return (
      <section className={classNames(css.root, className)}>
        <header className={css.header}>
          <h3 className={css.title}>{node.firstname ? node.firstname : 'Unknown'} {node.name ? node.name : 'Unknown'}</h3>
          <button className={css.close} onClick={closeHandler}>&#10005;</button>
        </header>
        <Relations {...props} title="Parents" items={enrichRelations(node.parents)} />
        <Relations {...props} title="Children" items={enrichRelations(node.children)} />
        <Relations {...props} title="Siblings" items={enrichRelations(node.siblings)} />
        <Relations {...props} title="Spouses" items={enrichRelations(node.spouses)} />
      </section>
    );
  },
);
