import React, { useMemo, useState, useCallback, useEffect } from 'react';
import type { Node, ExtNode } from './Lib/relatives-tree/lib/types';
import treePackage from './Lib/relatives-tree/package.json';

import ReactFamilyTree from 'react-family-tree';
import { SourceSelect } from './Components/SourceSelect.tsx';
import { PinchZoomPan } from './Components/PinchZoomPan.tsx';
import { FamilyNode } from './Components/FamilyNode.tsx';
import { NodeDetails } from './Components/NodeDetails.tsx';
import { NODE_WIDTH, NODE_HEIGHT, SOURCES, DEFAULT_SOURCE } from './Components/const.ts';
import { getNodeStyle } from './Components/utils.ts';
import CreatePersonForm from './Components/CreatePersonForm.tsx';

import css from './Components/App.module.css';
import averageTree from './Data/average_tree.json'; // Importer les données

export default React.memo(
  function App() {
    const [source, setSource] = useState(DEFAULT_SOURCE);
    const [nodes, setNodes] = useState(SOURCES[source]);

    useEffect(() => {
      setNodes(averageTree); // Charger les données depuis le fichier JSON
    }, []);

    const firstNodeId = useMemo(() => nodes[0]?.id, [nodes]);
    const [rootId, setRootId] = useState(firstNodeId);

    const [selectId, setSelectId] = useState<string>();
    const [hoverId, setHoverId] = useState<string>();
    const [showCreateForm, setShowCreateForm] = useState(false);

    const resetRootHandler = useCallback(() => setRootId(firstNodeId), [firstNodeId]);

    const changeSourceHandler = useCallback(
      (value: string, nodes: readonly Readonly<Node>[]) => {
        setRootId(nodes[0].id);
        setNodes(nodes);
        setSource(value);
        setSelectId(undefined);
        setHoverId(undefined);
      },
      [],
    );

    const selected = useMemo(() => (
      nodes.find(item => item.id === selectId)
    ), [nodes, selectId]);

    const handleCreatePerson = (person) => {
      const newPerson = {
        ...person,
        id: nodes.length + 1,
      };

      // Ajout de la nouvelle personne aux données existantes
      const updatedNodes = [...nodes, newPerson];

      // Mettre à jour les relations
      const updateRelations = (relations, type) => {
        return relations.map(rel => {
          const relatedNode = updatedNodes.find(node => node.id === rel.id);
          if (relatedNode) {
            relatedNode[type].push({ id: newPerson.id, type: rel.type });
          }
          return rel;
        });
      };

      newPerson.parents = updateRelations(newPerson.parents, 'children');
      newPerson.siblings = updateRelations(newPerson.siblings, 'siblings');
      newPerson.spouses = updateRelations(newPerson.spouses, 'spouses');
      newPerson.children = updateRelations(newPerson.children, 'parents');

      setNodes(updatedNodes);
      setShowCreateForm(false);
    };

    return (
      <div className={css.root}>
        <header className={css.header}>
          <h1 className={css.title}>
            Généa-Poirier
          </h1>
          <a href="https://github.com/Elitis12">Github</a>
        </header>
        <button onClick={() => setShowCreateForm(true)}>Créer une nouvelle personne</button>
        {nodes.length > 0 && (
          <PinchZoomPan min={0.5} max={2.5} captureWheel className={css.wrapper}>
            <ReactFamilyTree
              nodes={nodes}
              rootId={rootId}
              width={NODE_WIDTH}
              height={NODE_HEIGHT}
              className={css.tree}
              renderNode={(node: Readonly<ExtNode>) => (
                <FamilyNode
                  key={node.id}
                  node={node}
                  isRoot={node.id === rootId}
                  isHover={node.id === hoverId}
                  onClick={setSelectId}
                  onSubClick={setRootId}
                  style={getNodeStyle(node)}
                />
              )}
            />
          </PinchZoomPan>
        )}
        {rootId !== firstNodeId && (
          <button className={css.reset} onClick={resetRootHandler}>
            Reset
          </button>
        )}
        {selected && (
          <NodeDetails
            node={selected}
            className={css.details}
            onSelect={setSelectId}
            onHover={setHoverId}
            onClear={() => setHoverId(undefined)}
          />
        )}
        {showCreateForm && (
          <div className={css.modal}>
            <CreatePersonForm onCreate={handleCreatePerson} onClose={() => setShowCreateForm(false)} nodes={nodes} />
          </div>
        )}
      </div>
    );
  },
);
