import React, { useState } from 'react';
import css from './CreatePersonForm.module.css';

const CreatePersonForm = ({ onCreate, onClose, nodes }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    name: '',
    birthDate: '',
    birthPlace: '',
    gender: 'male',
    parents: [],
    siblings: [],
    spouses: [],
    children: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRelationChange = (name, index, field, value) => {
    const updatedArray = [...formData[name]];
    updatedArray[index][field] = value;
    setFormData({
      ...formData,
      [name]: updatedArray,
    });
  };

  const addRelationField = (name, defaultValue) => {
    setFormData({
      ...formData,
      [name]: [...formData[name], defaultValue],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  return (
    <div className={css.modalContent}>
      <button className={css.closeButton} onClick={onClose}>X</button>
      <form onSubmit={handleSubmit}>
        <label>
          Prénom:
          <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} required />
        </label><br />
        <label>
          Nom:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label><br />
        <label>
          Date de naissance:
          <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} required />
        </label><br />
        <label>
          Lieu de naissance:
          <input type="text" name="birthPlace" value={formData.birthPlace} onChange={handleChange} required />
        </label><br />
        <label>
          Genre:
          <select name="gender" value={formData.gender} onChange={handleChange} required>
            <option value="male">Homme</option>
            <option value="female">Femme</option>
          </select>
        </label><br />
        <label>
          Parents:
          {formData.parents.map((parent, index) => (
            <div key={index} className={css.relationField}>
              <select
                value={parent.id}
                onChange={(e) => handleRelationChange('parents', index, 'id', e.target.value)}
                required
              >
                <option value="" disabled>Select parent</option>
                {nodes.map(node => (
                  <option key={node.id} value={node.id}>
                    {node.firstname} {node.name}
                  </option>
                ))}
              </select>
              <select
                value={parent.type}
                onChange={(e) => handleRelationChange('parents', index, 'type', e.target.value)}
                required
              >
                <option value="blood">De sang</option>
                <option value="adopted">Adopté</option>
                <option value="step">Par alliance</option>
              </select>
            </div>
          ))}
          <button type="button" onClick={() => addRelationField('parents', { id: '', type: 'blood' })}>Ajouter un parent</button>
        </label><br />
        <label>
          Frères et Sœurs:
          {formData.siblings.map((sibling, index) => (
            <div key={index} className={css.relationField}>
              <select
                value={sibling.id}
                onChange={(e) => handleRelationChange('siblings', index, 'id', e.target.value)}
                required
              >
                <option value="" disabled>Select sibling</option>
                {nodes.map(node => (
                  <option key={node.id} value={node.id}>
                    {node.firstname} {node.name}
                  </option>
                ))}
              </select>
              <select
                value={sibling.type}
                onChange={(e) => handleRelationChange('siblings', index, 'type', e.target.value)}
                required
              >
                <option value="blood">De sang</option>
                <option value="half">Demi-Frère / Demi-Soeur</option>
                <option value="adopted">Adopté</option>
                <option value="step">Par alliance</option>
              </select>
            </div>
          ))}
          <button type="button" onClick={() => addRelationField('siblings', { id: '', type: 'blood' })}>Ajouter un frère ou une sœur</button>
        </label><br />
        <label>
          Enfants:
          {formData.children.map((child, index) => (
            <div key={index} className={css.relationField}>
              <select
                value={child.id}
                onChange={(e) => handleRelationChange('children', index, 'id', e.target.value)}
                required
              >
                <option value="" disabled>Select child</option>
                {nodes.map(node => (
                  <option key={node.id} value={node.id}>
                    {node.firstname} {node.name}
                  </option>
                ))}
              </select>
              <select
                value={child.type}
                onChange={(e) => handleRelationChange('children', index, 'type', e.target.value)}
                required
              >
                <option value="blood">De sang</option>
                <option value="adopted">Adopté</option>
                <option value="step">Par alliance</option>
              </select>
            </div>
          ))}
          <button type="button" onClick={() => addRelationField('children', { id: '', type: 'blood' })}>Ajouter un enfant</button>
        </label><br />
        <label>Conjoints:</label>
        {formData.spouses.map((spouse, index) => (
          <div key={index} className={css.relationField}>
            <select
              value={spouse.id}
              onChange={(e) => handleRelationChange('spouses', index, 'id', e.target.value)}
              required
            >
              <option value="" disabled>Select spouse</option>
              {nodes.map(node => (
                <option key={node.id} value={node.id}>
                  {node.firstname} {node.name}
                </option>
              ))}
            </select>
            <select
              value={spouse.type}
              onChange={(e) => handleRelationChange('spouses', index, 'type', e.target.value)}
              required
            >
              <option value="married">Marié(e)</option>
              <option value="partner">Conjoint</option>
              <option value="ex">Ex</option>
            </select>
          </div>
        ))}
        <button type="button" onClick={() => addRelationField('spouses', { id: '', type: 'married' })}>Ajouter un conjoint</button><br /><br />
        <button type="submit">Créer</button>
      </form>
    </div>
  );
};

export default CreatePersonForm;
